.containerTable{
    margin-top: 4%;
    margin-bottom: 4%;
}
.tableVaccini{
    overflow-x: auto;
}
.titleTable{  
    
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
}