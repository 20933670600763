@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,800");
@import url("https://fonts.googleapis.com/css?family=Lobster");

.titlePage {
  margin-top: 4%;
  margin-bottom: 5%;
  font-family: "Lobster", cursive;
  text-align: center;
  font-size: 2.5em;
}
