.footerset {
  padding: 3%;
  height: 100%;
  font-size: 1.1em;
  font-weight: 500;
}
.colFooter {
  text-align: center;
}
.lin {
  color: white;
  font-size: 1.2em;
}
.lin:hover {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
}
.rowLink {
  margin-top: 3%;
}
@media all and (max-width: 480px) {
  .colGlobalLinkSmartphone {
    width: 50% !important;
  }
  .colLinkSmartphone{
      padding: 0 !important;
      width: 33% !important;
  }
}
