@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,800);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Lobster);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.titlePage {
  margin-top: 4%;
  margin-bottom: 5%;
  font-family: "Lobster", cursive;
  text-align: center;
  font-size: 2.5em;
}

.consigliContainer {
  margin-top: 3%;
  margin-bottom: 4%;
}

.colConsigli {
  font-family: "Lobster", cursive;
  padding: 5%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.7em;
}

* {
  box-sizing: margin-box;
}
.tabData {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.tabData .card {
  padding: 0 !important;
  margin: 2%;
}

.tabData .card .card-header {
  font-weight: 500;
  padding: 2% 0 !important;
}
.tabData .card .card-body {
  font-size: 1.5em;
}

@media all and (max-width: 480px){
 
  .tabData .card .card-body{
    padding: 0!important;
  }
}
* {
  box-sizing: border-box;
}
.container-fluid{
  display: flex;
  justify-content:space-evenly;
  position: relative;
}
.leaflet-container {
  height: 675px;
  width: 430px;
  margin: 0 ;
  background: none !important;
}
.leaflet-control-container {
  display: none;
}
.leaflet-layer {
  opacity: 0 !important;
}


.clickP {
  /* position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%,-50%); */
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
}

.allContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width: 480px){
   .clickP {
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
  }
 
  .modale{
   
   
    display: block;
    position:absolute;
    bottom: 7%;
    margin-left: 4.5%;
    
    
    
  }
  .leaflet-container {
    height: 675px;
    width: 340px;
    margin: 0 ;
    background: none !important;
  }
  .card {
    height: 100%;
    width: 340px !important;
    padding:0 !important;
  }
}
.modale {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 6%;
}
.modale p,
h3 {
  font-weight: bold;
}
.modale h3 {
  text-transform: uppercase;
}
.card {
  height: 100%;
  width: 350px !important;
  padding: 0 !important;
}
.card-body {
  line-height: 1;
  overflow-y: auto !important;
}
.spanProvincie {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  opacity: 0.9;
}
.spanProvincie:hover{
  opacity: 1;
    font-weight: bold;
}

@media all and (max-width: 480px) {
  .clickP {
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
  }

  .modale {
    display: block;
    position: absolute;
    bottom: 7%;
    margin-left: 4.5%;
  }
  .card {
    height: 100%;
    width: 340px !important;
    padding: 0 !important;
  }
}

.containerProvincie{
    margin-top: 8%;
    margin-bottom: 13%;
    text-align: center;
}
.btn{
    padding: 0.2rem 0.7rem !important;
    margin-bottom: 3%;
}
.containerTable{
    margin-top: 4%;
    margin-bottom: 4%;
}
.tableVaccini{
    overflow-x: auto;
}
.titleTable{  
    
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
}
.footerset {
  padding: 3%;
  height: 100%;
  font-size: 1.1em;
  font-weight: 500;
}
.colFooter {
  text-align: center;
}
.lin {
  color: white;
  font-size: 1.2em;
}
.lin:hover {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
}
.rowLink {
  margin-top: 3%;
}
@media all and (max-width: 480px) {
  .colGlobalLinkSmartphone {
    width: 50% !important;
  }
  .colLinkSmartphone{
      padding: 0 !important;
      width: 33% !important;
  }
}

