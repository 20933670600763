.modale {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 6%;
}
.modale p,
h3 {
  font-weight: bold;
}
.modale h3 {
  text-transform: uppercase;
}
.card {
  height: 100%;
  width: 350px !important;
  padding: 0 !important;
}
.card-body {
  line-height: 1;
  overflow-y: auto !important;
}
.spanProvincie {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  opacity: 0.9;
}
.spanProvincie:hover{
  opacity: 1;
    font-weight: bold;
}

@media all and (max-width: 480px) {
  .clickP {
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
  }

  .modale {
    display: block;
    position: absolute;
    bottom: 7%;
    margin-left: 4.5%;
  }
  .card {
    height: 100%;
    width: 340px !important;
    padding: 0 !important;
  }
}
