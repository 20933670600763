* {
  box-sizing: border-box;
}
.container-fluid{
  display: flex;
  justify-content:space-evenly;
  position: relative;
}
.leaflet-container {
  height: 675px;
  width: 430px;
  margin: 0 ;
  background: none !important;
}
.leaflet-control-container {
  display: none;
}
.leaflet-layer {
  opacity: 0 !important;
}


.clickP {
  /* position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%,-50%); */
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
}

.allContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media all and (max-width: 480px){
   .clickP {
    width: 100%;
    text-align: center;
    font-size: 1em;
    font-weight: 500;
  }
 
  .modale{
   
   
    display: block;
    position:absolute;
    bottom: 7%;
    margin-left: 4.5%;
    
    
    
  }
  .leaflet-container {
    height: 675px;
    width: 340px;
    margin: 0 ;
    background: none !important;
  }
  .card {
    height: 100%;
    width: 340px !important;
    padding:0 !important;
  }
}